@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
}

.no-counter::-webkit-inner-spin-button,
.no-counter::-webkit-outer-spin-button {
    display: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}


.MuiDataGrid-toolbarContainer {
    background-color: #ffffff;
}

.MuiDataGrid-virtualScroller {
    border-radius: unset !important;
}

.muiInputNoBorder .css-953pxc-MuiInputBase-root-MuiInput-root::before,
.muiInputNoBorder .css-953pxc-MuiInputBase-root-MuiInput-root::after {
    border: none !important;
}

.swiper-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
}